import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { HashMap, LoadOptions, TranslateParams } from '@jsverse/transloco/lib/types';
import { combineLatest, distinctUntilChanged, filter, map, shareReplay } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { isEqual } from 'lodash-es';

export function injectLanguage() {
  return toSignal(inject(LanguageService).lang$);
}

export function getBrowserLanguage() {
  return window.localStorage.getItem('language') || window.navigator.language.split('-')[0]?.toLowerCase() || 'en';
}

@Injectable()
export class LanguageService {
  private readonly translocoService = inject(TranslocoService);

  private langChanges$ = this.translocoService.langChanges$;
  private langLoaded$ = this.translocoService.events$.pipe(
    filter((e) => e.type === 'translationLoadSuccess'),
    map(() => this.getActiveLang()),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  public lang$ = combineLatest([this.langChanges$, this.langLoaded$]).pipe(
    distinctUntilChanged(isEqual),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
    map(() => this.getActiveLang()),
  );

  constructor() {
    this.langLoaded$.pipe(takeUntilDestroyed()).subscribe();
  }

  public load(lang: string, options?: LoadOptions) {
    return this.translocoService.load(lang, options);
  }

  public setActiveLang(lang: string) {
    return this.translocoService.setActiveLang(lang);
  }

  public getAvailableLangs(): string[] {
    return this.translocoService.getAvailableLangs() as any;
  }

  public isAvailableLang(lang: string) {
    return this.translocoService.isLang(lang);
  }

  public getActiveLang() {
    return this.translocoService.getActiveLang();
  }

  public translate<T = any>(key: TranslateParams, params?: HashMap, lang?: string) {
    return this.translocoService.translate<T>(key, params, lang);
  }
}
