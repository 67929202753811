import { inject, provideAppInitializer } from '@angular/core';
import { ConfigService } from './config.service';
import { firstValueFrom } from 'rxjs';

export function provideConfigInitializer() {
  return provideAppInitializer(() => {
    const configService = inject(ConfigService);
    return firstValueFrom(configService.getConfig());
  });
}
