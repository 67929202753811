import { Provider, inject, provideAppInitializer } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

export function createSentryError() {
  return Sentry.createErrorHandler({
    showDialog: false,
    logErrors: true,
  });
}

export function provideSentry(options: { dsn: string; environment: string; version: string; sampleRate: number }): Provider {
  Sentry.init({
    dsn: options.dsn,
    environment: options.environment,
    release: `mtm@${options.version}`,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.httpClientIntegration(),
      // new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: options.sampleRate, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: options.sampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  return [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(Sentry.TraceService);
    }),
  ];
}

export function defineSentryUser<T extends { email: string; username: string }>(user?: T) {
  const sentryUser = user ? { id: user.email, email: user.email, username: user.username } : null;
  Sentry.configureScope((scope) => scope.setUser(sentryUser));
}
