import { inject, Injectable } from '@angular/core';
import { GET_FABRIC_API_ORIGIN } from './get-fabric-api-origin.token';
import { createFabric, FabricXhr } from '@isaia/entity/fabric';
import { HttpGetOptions, HttpResponse } from '@isaia/entity/http';
import { map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SetRequired } from 'type-fest';

@Injectable()
export class GetFabricApiService {
  private readonly API_ORIGIN_GET_BY_CODE_AND_COLOR = inject(GET_FABRIC_API_ORIGIN);
  private readonly http = inject(HttpClient);

  public getFabricByCodeAndColor(options: SetRequired<HttpGetOptions<{ color: string; code: string }>, 'params'>) {
    return this.http
      .get<HttpResponse<FabricXhr>>(this.API_ORIGIN_GET_BY_CODE_AND_COLOR, {
        ...options,
        params: {
          fabricColor: options.params.color,
          colorCode: options.params.code,
        },
      })
      .pipe(map((res) => createFabric(res.data)));
  }
}
