import { Component, ViewEncapsulation, ChangeDetectionStrategy, input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

export type ButtonType = 'fab' | 'mini-fab' | 'outline' | 'flat' | 'underline' | 'small' | 'chip';
export type ButtonColor = 'primary' | 'secondary' | 'transparent' | 'neutral';

@Component({
  selector: 'isa-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.type]': 'type()',
    '[attr.color]': 'color()',
    '[attr.disabled]': 'disabled()',
  },
  imports: [MatRippleModule],
})
export class ButtonComponent {
  public type = input<ButtonType | undefined>('outline');
  public color = input<ButtonColor | undefined>('primary');
  public disabled = input<boolean | undefined>();
  public action = input<'submit' | 'reset' | 'button' | undefined>('button');
  public tabindex = input<string | number | undefined>(0);
}
