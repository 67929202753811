import { Attribute, ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'isa-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet],
  host: {
    '[attr.position]': 'position',
  },
})
export class SpinnerComponent {
  constructor(
    @Attribute('label') public readonly label?: string | 'false',
    @Attribute('labelPosition') public readonly labelPosition?: 'before' | 'after',
    @Attribute('position') public readonly position?: 'inline' | 'fixed' | 'absolute',
  ) {
    this.label = coerceBooleanProperty(label) ? (label === 'true' ? 'Loading' : label) : undefined;
    this.labelPosition = labelPosition || 'after';
    this.position = position || 'inline';
  }
}
