import { RequireAtLeastOne } from 'type-fest';
import { HttpContextToken, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { extractHttpContextAndValue, WithContextOptions } from './http-context.model';

export type HttpErrorPopupConfig = RequireAtLeastOne<{
  title?: string;
  debug?: boolean;
  message?: string | ((error?: HttpErrorResponse) => string);
  disableClose?: boolean;
}>;
const HAS_ERROR_POPUP = new HttpContextToken<HttpErrorPopupConfig | boolean>(() => true);

export function withErrorPopup(options?: WithContextOptions<HttpErrorPopupConfig> | boolean) {
  const { context, value } = extractHttpContextAndValue(options);
  return context.set(HAS_ERROR_POPUP, value);
}

export function hasErrorPopup(req: HttpRequest<unknown>) {
  return req.context.get(HAS_ERROR_POPUP);
}
